@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Wallpoet&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap"); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.border-gradient {
  position: relative;
  border-radius: 12px;
  z-index: 1;
}

.top-border {
  position: relative;
  border-bottom: 2px solid #202020;
  border-radius: 8px;
  overflow: hidden;
}

.top-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-radius: 8px;
  mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 0));
  border-image: linear-gradient(180deg, #2CE85E 0%, #202020 68.55%);
  border-image-slice: 1;
  pointer-events: none;
}

.bottom-border {
  position: relative;
  border-top: 2px solid #202020;
  border-radius: 8px;
  overflow: hidden;
}

.bottom-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 3px solid transparent;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-radius: 8px;
  mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 0));
  border-image: linear-gradient(180deg, #2CE85E 0%, #202020 68.55%);
  border-image-slice: 01;
  pointer-events: none;
}


.border-gradient::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    #898b8a 0%,
    #6fae80 33.75%,
    #61f889 66.3%,
    #afc8b5 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
}


.box-shadow{
  box-shadow: 0px 4px 10.9px 0px #2CE85E;
}


.text-stroke {
  -webkit-text-stroke: 1px black; /* Stroke color and width */
  color: white; /* Base text color */
}


@font-face {
  font-family: "Raleway-Black";
  src: url("./assets/fonts/raleway/Raleway-Black.ttf");
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/raleway/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Raleway-Medium";
  src: url("./assets/fonts/raleway/Raleway-Medium.ttf");
}

@font-face {
  font-family: "Raleway-Semibold";
  src: url("./assets/fonts/raleway/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-Semibold";
  src: url("./assets/fonts/inter/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-Extrabold";
  src: url("./assets/fonts/inter/Inter-ExtraBold.ttf");
}
